import React from 'react';
import styles from './style.module.scss';
import posed from 'react-pose';

function PortraitImageDiv(props) {
  const ImageDiv = posed.div({
    enter: {
      opacity: 1,
      transition: {
        duration: 300,
        ease: 'easeIn',
      },
    },
    exit: { opacity: 0 },
  });
  return (
    <ImageDiv
      className={
        styles['portrait'] +
        ' , ' +
        (props.hideShadow ? styles['hide-shadow'] : '')
      }
    >
      {props.children}
    </ImageDiv>
  );
}

export default PortraitImageDiv;

import React from 'react';
//import { LazyImage } from 'components/lazy-image';
import { htmlToReactParser } from 'modules/html-to-react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmallPlus } from 'constants/breakpoints';
import styles from './style.module.scss';

export const ShowCase = ({
  movie,
  mobileMovie,
  poster,
  mobilePoster,
  fullscreen,
  message,
}) => {
  const { width } = useWindowSize();
  const smallScreen = width < bpSmallPlus;

  return (
    <div
      className={
        styles['showcase'] +
        ' , ' +
        (fullscreen ? styles['showcase-fullscreen'] : '') +
        ' , ' +
        (smallScreen ? styles['mobile-vid'] : '')
      }
    >
      <div class={styles['message-overlay']}>
        <div>
          <h3>{htmlToReactParser.parse(message)}</h3>
        </div>
      </div>
      {!smallScreen && (
        <video autoPlay muted playsInline loop poster={poster}>
          <source src={movie} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {smallScreen && mobileMovie && (
        <video autoPlay muted playsInline loop poster={mobilePoster}>
          <source src={mobileMovie} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export const SECTION_CLARITY = 'clarity';
export const SECTION_CARESSES = 'caresses';
export const SECTION_CIRCULAR = 'circular';
export const SECTION_CIRCULAR_REVERSE = 'circular-reverse';
export const SECTION_COLLABORATORS = 'collaborators';
export const SECTION_CONTACT_FORM = 'contact-form';
export const SECTION_CALLOUT = 'callout';
export const SECTION_BEAUTY = 'beauty';
export const SECTION_REALISE = 'realise';
export const SECTION_ENHANCE = 'enhance';
export const SECTION_FAQS = 'faq';
export const SECTION_MIDI_REVERSE = 'midi-reverse';
export const SECTION_MIDI = 'midi';
export const SECTION_PARTNERS = 'partners';
export const SECTION_PROJECTS = 'projects';
export const SECTION_STORY = 'story-description';
export const SECTION_PRODUCT_INTRO = 'product-intro';

export const ROUTE_HOME = '/';
export const ROUTE_PRODUCTS = '/products';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_WHY_ORLUNA = '/why-orluna';
export const ROUTE_OUR_STORY = '/our-story';
export const ROUTE_DOWNLOADS = '/downloads';
export const ROUTE_NEWS = '/news';
export const ROUTE_FAQS = '/faqs';
export const ROUTE_GET_IN_TOUCH = '/get-in-touch';
export const ROUTE_WORK_WITH_US = '/work-with-us';
export const ROUTE_PRIVACY = '/privacy-policy';
export const ROUTE_COOKIES = '/website-terms-cookie-policy';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_PEOPLE = '/people';
export const ROUTE_RANGES = '/ranges';
export const ROUTE_SUSTAINABILITY = '/circular-product';
export const ROUTE_MIDI = '/midi-range';

import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
//import { useWindowSize } from 'hooks/use-window-size';
//import { useScrolling } from 'hooks/use-scrolling';
//import { bpXLarge } from 'constants/breakpoints';
import logoIcon from 'assets/images/floating_logo_icon.png';
import styles from './style.module.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export const FloatingLogo2 = ({ section, size, offsetHelper = 0 }) => {
  //const { scrollY } = useScrolling(5000); // Smooth scroll hook
  //const { width } = useWindowSize(); // Hook for window size
  const logo = useRef(false);
  //const [offsetY, setOffsetY] = useState(0);
  //const [sectionOffsetTop, setSectionOffsetTop] = useState(0);

  const floatingIconClasses = classNames({
    [`${styles['absolute']}`]: true,
    [`${styles[section]}`]: section,
    [`${styles[size]}`]: size,
  });

  //const transform = `translateY(calc(-${offsetHelper}px - ${offsetY}px))`;

  useEffect(() => {
    if (section === 'parallax-sun') {
      gsap.registerPlugin(ScrollTrigger);

      gsap.to(logo.current, {
        yPercent: -80,
        ease: 'none',
        scrollTrigger: {
          trigger: 'parallax-sun',
          scrub: true,
        },
      });
    }
  }, [section]);

  return (
    <div
      ref={logo}
      className={floatingIconClasses}
      style={{
        backgroundImage: `url(${logoIcon})`,
        willChange: 'transform', // Hint for better performance
      }}
    />
  );
};

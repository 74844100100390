import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { htmlToReactParser } from 'modules/html-to-react';
import { SliderComparison } from 'components/slider-comparison';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmallPlus } from 'constants/breakpoints';
import { THEME_DARK } from 'constants/color-theme';
import styles from './style.module.scss';
import { Button } from 'components/buttons';
import { Link } from 'react-router-dom';

const TextContent = posed.div({
  enter: {
    x: '0',
    delayChildren: 450,
    staggerChildren: 150,
  },
});

const Text = posed.span({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  exit: { y: 20, opacity: 0 },
});

const ImageDiv = posed.div({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeInOut',
    },
  },
  exit: { opacity: 0 },
});

export const ImageAndText = ({
  sectionName,
  title,
  image,
  mobileImage,
  vid,
  text,
  comparison,
  comparisonImages,
  buttonLink,
  buttonLabel,
  children,
  hideBorder,
  tagline,
}) => {
  const [entered, setEntered] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const { width } = useWindowSize();
  const smallScreen = width < bpSmallPlus;

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  const handleOnView = (visiblity) => setVisibility(visiblity);

  return (
    <>
      <VisibilitySensor partialVisibility onChange={handleOnView}>
        <section className={`${styles[sectionName]} container`}>
          <div className={styles['wrapper']}>
            {comparison && (
              <SliderComparison comparisonImages={comparisonImages} />
            )}
            {!comparison && (!smallScreen || !mobileImage) && !vid && (
              <ImageDiv
                className={styles['image-wrapper']}
                pose={entered ? 'enter' : 'exit'}
              >
                <img src={image} alt="Orluna's product" />
                {!hideBorder && <div className={styles['bg-color']} />}
              </ImageDiv>
            )}
            {!comparison && mobileImage && !vid && smallScreen && (
              <ImageDiv
                className={styles['image-wrapper']}
                pose={entered ? 'enter' : 'exit'}
              >
                <img src={mobileImage} alt="Orluna's product" />
                {!hideBorder && <div className={styles['bg-color']} />}
              </ImageDiv>
            )}
            {vid && (
              <ImageDiv
                className={styles['image-wrapper']}
                pose={entered ? 'enter' : 'exit'}
              >
                <video
                  autoPlay="autoPlay"
                  playsInline="playsInline"
                  loop="loop"
                  muted="muted"
                >
                  <source src={vid} type="video/mp4" />
                </video>
                {!hideBorder && <div className={styles['bg-color']} />}
              </ImageDiv>
            )}
            {text && (
              <TextContent
                className={styles['text-content']}
                pose={entered ? 'enter' : 'exit'}
              >
                <Text>
                  {tagline && <img src={tagline} alt="" />}
                  <h2 className={styles.title}>{title}</h2>
                </Text>
                {children}
                <Text>{htmlToReactParser.parse(text)}</Text>
                {buttonLabel && (
                  <Link to={buttonLink}>
                    <Button theme={THEME_DARK} label={buttonLabel} />
                  </Link>
                )}
              </TextContent>
            )}

            {title && !text && (
              <TextContent
                className={styles['text-content']}
                pose={entered ? 'enter' : 'exit'}
              >
                <Text>
                  <h3 className={styles.title}>{title}</h3>
                </Text>
                {children}
              </TextContent>
            )}
          </div>
        </section>
      </VisibilitySensor>
    </>
  );
};

ImageAndText.propTypes = {
  sectionName: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  comparison: PropTypes.bool,
  comparisonImages: PropTypes.objectOf(function (comparison) {
    if (comparison) return PropTypes.object.isRequired;
  }),
};

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeMenu,
  setProductsView,
  fetchNavMenus,
  fetchNotification,
  fetchPromotionalModule,
} from 'redux/actions';
import { fetchSignUpFormVisibility } from 'redux/actions/sign-up-form';
import { GRID } from 'constants/view';
import {
  ROUTE_CONTACT,
  ROUTE_COOKIES,
  ROUTE_DOWNLOADS,
  ROUTE_FAQS,
  ROUTE_GET_IN_TOUCH,
  ROUTE_HOME,
  ROUTE_NEWS,
  ROUTE_OUR_STORY,
  ROUTE_PEOPLE,
  ROUTE_PRIVACY,
  ROUTE_PRODUCTS,
  ROUTE_PROJECTS,
  ROUTE_WHY_ORLUNA,
  ROUTE_WORK_WITH_US,
  ROUTE_RANGES,
  ROUTE_SUSTAINABILITY,
  ROUTE_MIDI,
} from 'constants/router-links';
import './App.scss';

import { BackToTopBtn } from 'components/back-to-top';
import { Notification } from 'components/notification';
import { Loading } from 'components/loading';
import GoogleAnalytics from 'components/google-analytics';

import { Home } from 'screens/home';
import { ProductListing } from 'screens/product-listing';
import { ProductDetail } from 'screens/product-detail';
import { RangePage } from 'screens/range';
import { ProjectListingScreen } from 'screens/project-listing';
import { ProjectDetailScreen } from 'screens/project-detail';
import { OurStory } from 'screens/our-story';
import { DownloadsLibrary } from 'screens/downloads-library';
import { WhyOrluna } from 'screens/why-orluna';
import { Sustainability } from 'screens/sustainability';
import { ContactScreen } from 'screens/contact';
import { GetInTouchScreen } from 'screens/get-in-touch';
import { People } from 'screens/people';
import { PrivacyPolicyScreen } from 'screens/privacy-policy';
import { TermsCookiePolicyScreen } from 'screens/terms-cookie-policy';
import { FourOhFourScreen } from 'screens/four-oh-four';
import { WorkWithUsScreen } from 'screens/work-with-us';
import { NewsListingScreen } from 'screens/news-listing';
import { NewsDetailScreen } from 'screens/news-detail';
import { FaqsScreen } from 'screens/faqs';
import { MidiRange } from 'screens/midi-range';

const App = (props) => {
  const dispatch = useDispatch();
  const { pathname, search } = props.location;
  const { notification } = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(fetchSignUpFormVisibility());
    dispatch(fetchNavMenus());
    dispatch(closeMenu());
    if (pathname === '/') dispatch(setProductsView(GRID));
    window.scrollTo(0, 0);
  }, [dispatch, pathname, search]);

  useEffect(() => {
    dispatch(fetchNotification());
    dispatch(fetchPromotionalModule());
  }, [dispatch]);

  const navLoading = useSelector((state) => state.navMenus.loading);

  if (navLoading) return <Loading />;

  return (
    <>
      <BackToTopBtn />
      {notification.length > 0 && (
        <Notification notification={notification} pathname={pathname} />
      )}
      <Switch>
        <Route exact path={ROUTE_HOME} render={() => <Home />} />
        <Route
          path={`${ROUTE_PRODUCTS}/:slug/:variant`}
          component={ProductDetail}
        />
        {/* allow the user to go to the product detail page even if he does not type the variant
         * and there we redirect him to the correct page with
         * if(variant === undefined && product.url) return <Redirect to={product.url} />
         */}
        <Route path={`${ROUTE_PRODUCTS}/:slug`} component={ProductDetail} />
        <Route path={ROUTE_PRODUCTS} render={() => <ProductListing />} />
        <Route path={`${ROUTE_RANGES}/:range`} component={RangePage} />
        <Route
          path={`${ROUTE_PROJECTS}/:slug`}
          component={ProjectDetailScreen}
        />
        <Route path={ROUTE_PROJECTS} render={() => <ProjectListingScreen />} />
        <Route path={ROUTE_WHY_ORLUNA} render={() => <WhyOrluna />} />
        <Route path={ROUTE_SUSTAINABILITY} render={() => <Sustainability />} />
        <Route path={ROUTE_MIDI} render={() => <MidiRange />} />
        <Route path={ROUTE_OUR_STORY} render={() => <OurStory />} />
        <Route path={ROUTE_DOWNLOADS} component={DownloadsLibrary} />
        <Route path={ROUTE_CONTACT} render={() => <ContactScreen />} />
        <Route path={ROUTE_GET_IN_TOUCH} render={() => <GetInTouchScreen />} />
        <Route path={ROUTE_WORK_WITH_US} render={() => <WorkWithUsScreen />} />
        <Route path={`${ROUTE_NEWS}/:slug`} component={NewsDetailScreen} />
        <Route path={ROUTE_NEWS} render={() => <NewsListingScreen />} />
        <Route path={ROUTE_FAQS} render={() => <FaqsScreen />} />
        <Route path={ROUTE_PRIVACY} render={() => <PrivacyPolicyScreen />} />
        <Route path={ROUTE_PEOPLE} render={() => <People />} />
        <Route
          path={ROUTE_COOKIES}
          render={() => <TermsCookiePolicyScreen />}
        />
        <Route component={FourOhFourScreen} />
      </Switch>
      <GoogleAnalytics />
    </>
  );
};

export default App;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchCollaborators,
  fetchBookConsultation,
  fetchProducts,
  fetchRanges,
} from 'redux/actions';
import { getPage } from 'modules/api';
import urls from 'constants/urls.json';
import { SIZE_SMALL, SIZE_MEDIUM } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { PAGE_MIDI } from 'constants/page-name';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SECTION_MIDI, SECTION_MIDI_REVERSE } from 'constants/section-name';
import { Loading } from 'components/loading';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { SimpleHero } from 'components/simple-hero';
import PortraitImageDiv from 'components/portrait-image-div';
import { Callout } from 'components/callout';
import { ImageAndText } from 'components/image-and-text';
import { SectionWithPortrait } from 'components/section-with-portrait';
import { ShowCase } from 'components/ShowCase';
import { ParallaxSection } from 'components/ParallaxSection';
import parallaxBG from '../assets/images/parallaxBG.png';
import movie from '../assets/carbolite-movie-noaudio.mp4';
import { Button } from 'components/buttons';
import './midi.scss';
import { FloatingLogo2 } from 'components/floating-logo2';
import midiBG from '../assets/images/MIDI-Range2.png';
import vase from '../assets/short-vase.mp4';
import landing from '../assets/landing-notext.mp4';
import landingMobile from '../assets/landing-mobile-notext.mp4';
import carboliteMobile from '../assets/carbolite-mobile-movie.mp4';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import carboliteposter from '../assets/images/carbolitebg.jpg';
import carbolitemobileposter from '../assets/images/carbolitebgmobile.jpg';
import midimobileposter from '../assets/images/midiposermobile.png';
//import { ScrollTrigger } from 'gsap/all';

export const MidiRange = () => {
  //const { bannerImages } = useSelector((state) => state.bookConsultation);
  //const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const floatingImage = useRef(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (floatingImage.current) {
        gsap.to(floatingImage.current, {
          yPercent: -200,
          ease: 'none',
          scrollTrigger: {
            trigger: floatingImage.current,
            scrub: true,
          },
        });
      } else {
        console.error('floatingImage ref is not initialized!');
      }
    }, 2000); // Short delay

    return () => clearTimeout(timeout); // Cleanup the timeout on unmount
  }, []);

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchCollaborators());
    dispatch(fetchProducts({}));
    dispatch(fetchBookConsultation());

    async function fetchPage() {
      const page = await getPage(urls['page'], 'midi-range');
      setPageData(page.acf);
      isLoading(false);
    }

    fetchPage();
  }, [dispatch]);

  if (loading) return <Loading />;

  console.log(pageData.intro.image.url);

  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} gradient />
      <SimpleHero
        pageName={PAGE_MIDI}
        theme={THEME_DARK}
        desktop={pageData.hero.image}
        mobile={midimobileposter}
        videoDesktop={landing}
        videoMobile={landingMobile}
        textTagline="midi"
        smallTitle="introducing, <br>super-deep recess"
        //smallScreen={}
      />

      <section className="" style={{ paddingTop: '100px' }}>
        <SectionWithPortrait>
          <div>
            <div className="midi-intro">
              <div className="midi-bgtext">
                <img alt="orluna midi range" src={midiBG} />
              </div>
              <SlideUp>
                <h3>a new class of downlight</h3>
                <p>Midi is a range of beautiful 35mm downlights.</p>
                <p>
                  Small, but deeply recessed with a cross-beam optic for
                  impossibly low glare, even with a white baffle.
                </p>
              </SlideUp>
            </div>
          </div>
          <PortraitImageDiv hideShadow={true}>
            <span></span>
            <Callout
              imageDesktop={pageData.intro.image.url}
              imageMobile={pageData.intro.image.url}
              smaller={true}
            />
          </PortraitImageDiv>
        </SectionWithPortrait>
      </section>

      <section>
        <div className="container">
          <SlideUp>
            <ImageAndText
              sectionName={SECTION_MIDI}
              title={pageData.image_and_text_1.title}
              image={pageData.image_and_text_1.image}
              mobileImage={pageData.image_and_text_1.image_mobile}
              vid={vase}
            />
          </SlideUp>
        </div>
      </section>

      <section>
        <div
          ref={floatingImage}
          className="container floatingimage"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PortraitImageDiv hideShadow={false}>
            <span></span>
            <Callout
              style2={{ margin: '0 auto' }}
              imageDesktop={pageData.image_and_text.image}
              imageMobile={pageData.image_and_text.image}
            />
          </PortraitImageDiv>
        </div>
      </section>

      <section className="interactive-section">
        <ParallaxSection background={parallaxBG}></ParallaxSection>
      </section>

      <section className="midi-belowinteractive">
        <SlideUp>
          <SectionWithPortrait>
            <div>
              <div className="midi-intro" style={{ marginTop: '100px' }}>
                <h3>infinity, our most beautiful downlight yet</h3>
              </div>
            </div>
            <PortraitImageDiv hideShadow={true}>
              <span></span>
              <Callout
                imageDesktop={
                  'https://assets.orluna.com/uploads/2024/11/MIDI-CEILING.jpg'
                }
                imageMobile={
                  'https://assets.orluna.com/uploads/2024/11/MIDI-CEILING.jpg'
                }
                smaller={true}
              />
            </PortraitImageDiv>
          </SectionWithPortrait>
        </SlideUp>
      </section>

      <ShowCase
        movie={movie}
        mobileMovie={carboliteMobile}
        poster={carboliteposter}
        mobilePoster={carbolitemobileposter}
        fullscreen={true}
        message="made from a revolutionary <br> new material that dramatically <br> reduces imported carbon"
      ></ShowCase>

      <section>
        <div className="container midi-carbolite">
          <SlideUp>
            <ImageAndText
              sectionName={SECTION_MIDI_REVERSE}
              //title={pageData.image_and_text_1.title}
              title="carbolite"
              image={
                'https://assets.orluna.com/uploads/2024/11/90-recycled.png'
              }
              mobileImage={
                'https://assets.orluna.com/uploads/2024/11/90-recycled.png'
              }
              text="100% european raw material"
              buttonLabel="find out more"
              buttonLink="/circular-product"
            />
          </SlideUp>
          <FloatingLogo2
            section="carbolite-section"
            size={SIZE_SMALL}
          ></FloatingLogo2>
        </div>
      </section>

      <section className="midi-footer">
        <div>
          <div>
            <img
              src="https://assets.orluna.com/uploads/2024/11/MIDI-ROOM.jpg"
              alt="Orluna Midi Range"
            />
          </div>
          <div>
            <SlideUp>
              <h2>your whole world, beautifully lit</h2>
            </SlideUp>

            <SlideUp>
              <Link to="/products/infinity-midi-trimless">
                <Button
                  size={SIZE_MEDIUM}
                  theme={THEME_DARK}
                  label={'click through to infinity'}
                />
              </Link>
            </SlideUp>
          </div>
        </div>
      </section>

      <Footer />
    </ErrorBoundary>
  );
};

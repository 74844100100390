import React from 'react';
import { Link } from 'react-router-dom';
import { abbreviateAllVariants } from 'modules/abbreviate-all-variants';
import { htmlToReactParser } from 'modules/html-to-react';
import { New } from 'components/icons';
import { TooltipFireplate } from 'components/tooltip-fireplate';
import { TooltipCircular } from 'components/tooltip-circular';
import styles from './style.module.scss';
import infinityMidi from './infinity-white/inf-midi-adj-w.png';
import infinityMidiTrimless from './infinity-white/inf-midi-adj-trimless-w.png';

export const ProductFamilies = ({ families }) => {
  return (
    <main className={styles.family}>
      {Object.values(families).map((family, i) => {
        const quantity = family.products.length;
        return (
          <div className={styles.wrapper} key={family.ID}>
            <h2>
              {htmlToReactParser.parse(family.title)} <span> / {quantity}</span>
            </h2>
            <ul>
              {family.products.map((item, i) => {
                console.log(item);
                return (
                  <>
                    <li key={i}>
                      <Link to={item?.default?.url || item.url}>
                        {item.isNew && <New />}
                        <div className={styles['tooltip-holder']}>
                          {item.isCircular && <TooltipCircular />}
                          {(item.hasFireplate || item.fireplate) && (
                            <TooltipFireplate />
                          )}
                        </div>
                        <img
                          src={item?.familyListingThumbnail || item.image}
                          alt={item.title}
                        />
                        <div>
                          <h3>{item.title}</h3>
                          <p>
                            {abbreviateAllVariants(
                              item.variants.map(
                                (variant) => variant.variant || variant.name
                              )
                            ).join(' / ')}
                          </p>
                          {item.metaFields.map((meta, index) => (
                            <p key={index}>{meta}</p>
                          ))}
                        </div>
                      </Link>
                    </li>
                    {(item.title === 'infinity midi' ||
                      item.title === 'infinity midi trimless') && (
                      <li key={i}>
                        <Link to={item?.default?.url || item.url}>
                          {item.isNew && <New />}
                          <div className={styles['tooltip-holder']}>
                            {item.isCircular && <TooltipCircular />}
                            {(item.hasFireplate || item.fireplate) && (
                              <TooltipFireplate />
                            )}
                          </div>
                          <img
                            src={
                              item.title === 'infinity midi'
                                ? infinityMidi
                                : infinityMidiTrimless
                            }
                            alt={item.title + ' (white)'}
                          />
                          <div>
                            <h3>{item.title + ' (white)'}</h3>
                            <p>
                              {abbreviateAllVariants(
                                item.variants.map(
                                  (variant) => variant.variant || variant.name
                                )
                              ).join(' / ')}
                            </p>
                            {item.metaFields.map((meta, index) => (
                              <p key={index}>{meta}</p>
                            ))}
                          </div>
                        </Link>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        );
      })}
    </main>
  );
};

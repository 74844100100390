import React, { useEffect, useRef } from 'react';
import styles from './style.module.scss';
import { FloatingLogo2 } from 'components/floating-logo2';
import { SIZE_SMALL } from 'constants/sizes';
import { SlideUp } from 'components/animations/slide-up';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export const ParallaxSection = ({ background }) => {
  const paraImg = useRef(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(paraImg.current, {
      yPercent: -50,
      ease: 'none',
      scrollTrigger: {
        trigger: 'img-trigger',
        scrub: true,
      },
    });
  }, []);

  return (
    <div>
      <div className={styles['parallax-wrapper']}>
        <FloatingLogo2 section="parallax-sun" size={SIZE_SMALL}></FloatingLogo2>
        <div className={styles['middle-text']}>
          <SlideUp>
            <h3>the beauty of natural light</h3>
          </SlideUp>
        </div>
        <img
          className="img-trigger"
          ref={paraImg}
          src={background.toString()}
          alt="background of lavendar fields"
        />
      </div>
    </div>
  );
};

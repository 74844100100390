import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { FloatingLogo } from 'components/floating-logo';
import { SlideUp } from 'components/animations';
import {
  ROUTE_PRODUCTS,
  ROUTE_WHY_ORLUNA,
  ROUTE_SUSTAINABILITY,
} from 'constants/router-links';
import { SECTION_REALISE, SECTION_ENHANCE } from 'constants/section-name';
import { THEME_DARK } from 'constants/color-theme';
import { SIZE_SMALL } from 'constants/sizes';
import styles from './style.module.scss';

import { Callout } from 'components/callout';

export const RealiseAndEnhance = ({
  videoDesktop,
  videoMobile,
  videoImageDesktop,
  videoImageMobile,
  videoCaption,
  enhanceImage,
  productCollection,
  bestLitEffect,
  circularImageDesktop,
  circularImageMobile,
  circularProduct,
}) => {
  return (
    <section className={`${styles['realise-and-enhance']}`}>
      <div className={`${styles.flex} ${styles.wrapper} container`}>
        <SlideUp>
          <div className={`${styles.flex} ${styles.realise}`}>
            <div
              className={`${styles['negative-margin']} ${styles['realise-left-side']}`}
            >
              <span>{videoCaption}</span>
              <Callout
                videoDesktop={videoDesktop}
                videoMobile={videoMobile}
                imageDesktop={videoImageDesktop}
                imageMobile={videoImageMobile}
              />
            </div>
            <div className={styles['realise-right-side']}>
              <FloatingLogo section={SECTION_REALISE} size={SIZE_SMALL} />
              <div className={styles['realise-content']}>
                <h2>{productCollection.title}</h2>
                <p>{productCollection.description}</p>
                <Link to={ROUTE_WHY_ORLUNA}>
                  <Button
                    theme={THEME_DARK}
                    label={productCollection.button_title}
                  />
                </Link>
                <h3>{productCollection.feature_text}</h3>
              </div>
            </div>
          </div>
        </SlideUp>
        <SlideUp>
          <div className={`${styles.flex} ${styles.enhance}`}>
            <div
              className={`${styles['negative-margin']} ${styles['enhance-left-side']}`}
            >
              <div className={styles['enhance-content']}>
                <h2>{bestLitEffect.title}</h2>
                <p>{bestLitEffect.description}</p>
                <Link to={ROUTE_PRODUCTS}>
                  <Button
                    theme={THEME_DARK}
                    label={bestLitEffect.button_title}
                  />
                </Link>
              </div>
            </div>
            <FloatingLogo
              section={SECTION_ENHANCE}
              size={SIZE_SMALL}
              offsetHelper={1200}
            />
            <div
              className={styles['enhance-right-side']}
              style={{
                background: `url(${enhanceImage}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
          </div>
        </SlideUp>

        <SlideUp>
          <div className={`${styles.flex} ${styles.realise}`}>
            <div className={`${styles['realise-left-side']}`}>
              <Callout
                imageDesktop={circularImageDesktop}
                imageMobile={circularImageMobile}
              />
            </div>
            <div className={styles['realise-right-side']}>
              <div className={styles['realise-content']}>
                <h2>carbolite</h2>
                <p>{circularProduct.description}</p>
                <Link to={ROUTE_SUSTAINABILITY}>
                  <Button
                    theme={THEME_DARK}
                    label={circularProduct.button_title}
                  />
                </Link>
              </div>
            </div>
          </div>
        </SlideUp>
      </div>
    </section>
  );
};
